require("./bootstrap");

(() => {
    const header = document.querySelector(".js-header");
    const backToTopArrow = document.querySelector(".js-back-to-top");

    function init() {
        eventListeners();

        setTimeout(() => {
            stickyHeader();
            backToTop();
        }, 1000);
    }

    init();

    function stickyHeader() {
        if (header) {
            if (window.scrollY > 0) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }

    function backToTop() {
        if (backToTopArrow && window.scrollY > 0) {
            if (
                backToTopArrow &&
                window.innerHeight + window.scrollY >
                    document.body.offsetHeight - 300
            ) {
                backToTopArrow.classList.add("visible");
            } else {
                backToTopArrow.classList.remove("visible");
            }
        }
    }

    function eventListeners() {
        if (backToTopArrow) {
            backToTopArrow.addEventListener("click", () => {
                $("html, body").animate({ scrollTop: 0 }, "slow");
            });
        }

        window.addEventListener("scroll", () => {
            stickyHeader();
            backToTop();
        });
    }
})();
